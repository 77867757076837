@import './Main.css';

@keyframes Appear {

    0% { opacity: 0 ;}
    10% { opacity: 0 ;}
    100% { opacity: 1 ;}
}

/* COLLAPSE */

.collapse {

    background-color: var(--tertiary);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin: 0 auto;
    font-size: clamp(0.8125rem, 0.6161rem + 0.9821vw, 1.5rem);
}

.collapse_title {

    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--secondary);
    color: var(--primary);
    border-radius: 5px;
    font-weight: 500;
    padding: 10px 15px;
    cursor: pointer;
    transition: box-shadow 0.5s, text-shadow 0.5s;
}

.collapse_title:hover {

    text-shadow: 0 0 10px rgb(0 0 0 / 0.2);
    box-shadow: 0 0 15px var(--secondary);
}
    
.collapse_arrow {

    margin-left: auto;
    transition: filter 0.5s;
}

.collapse_title:hover .collapse_arrow {
    filter: drop-shadow(0 0 10px rgb(0 0 0 / 0.4));
}

.collapse_arrow_down {

    transition: transform 0.5s;
    transform: rotate(0deg);
}

.collapse_arrow_up {

    transition: transform 0.5s;
    transform: rotate(180deg);
}
    
.collapse_content {

    max-height: 0;
    transition: padding 0.3s;
    visibility: hidden;
    overflow: hidden;
    color: var(--secondary);
    padding-left: 12px;
    margin-top: -5px;
}
    
.collapse_content_show {

    display: flex;
    flex-direction: column;
    gap: 5px;
    letter-spacing: 1px;
    padding: 10px;
    color: var(--secondary);
    animation: Appear 0.7s;
    transition: padding 0.3s;
    padding: 24px 12px 12px 12px;
}

/* LODGE_COLLAPSE */

.lodge_collapse {

    display: flex;
    justify-content: space-between;
    gap: 70px;
    margin: 20px auto 30px;
    width: 95%;
}

.lodge_collapse_item {

    width: 50%;
}

/* ABOUT_COLLAPSE */

.about_collapse{

    margin: 40px auto;
    width: 82%;
}

/* MEDIA SCREEN */

@media screen and (max-width: 1230px) {

    .arrow{

        width: 12px;
    }     
}

@media screen and (max-width: 650px) {

    .lodge_collapse {

        flex-direction: column;
        gap: 20px;
        margin-top: 20px;
    }

    .about_collapse,
    .lodge_collapse_item {

        width: 100%;
    }
}

@media  screen and ( max-width: 415px ) {

    .lodge_collapse {

        margin-top: 0;
    }
}


