@import './Main.css';

/* SLIDER */

.slider {

    position: relative;
    display: flex;
    height: 415px;
    border-radius: 25px;
    width: 95%;
    margin: 43px auto 30px;
    box-shadow: 0 0 50px rgb(0 0 0 / 0.4);
    overflow: hidden;
}

.slider_slide {

    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.slider_arrow {

    position: absolute;
    top: 50%;
    height: 15%;
    transform: translateY(-50%);
    cursor: pointer;
    filter: drop-shadow(0 0 10px rgb(0 0 0 / 0.4));
    transition: filter 1s;
}

.slider_arrow:hover {
    filter: drop-shadow(0 0 10px var(--secondary));
}

.slider_arrow:active {

    transform: translateY(-50%) scale(0.95);
}

.slider_arrow_right {

    right: 25px;
}

.slider_arrow_left {

    left: 25px;
}

.slider_counter {

    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
    color: var(--primary);
    font-weight: 500;
    font-size: clamp(14px, 2vw, 18px);
    text-shadow: 0 0 8px rgb(0 0 0 / 1);
}