


/* COULEURS */

:root {

    --primary : #fff;
    --secondary : #FF6060;
    --tertiary : #F7F7F7;
}

/* SCROLLBAR */

::-webkit-scrollbar{
    width: 10px;
}

::-webkit-scrollbar-thumb {
    background: var(--secondary);
}

*, ::before, ::after {

    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat';
    font-style: normal;
    scrollbar-color: var(--secondary) transparent;
    scrollbar-width: thin;
}

body {

    margin: 0 auto;
}

.page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.lead {

    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    flex: 1;
}

a {

    text-decoration: none;
    color: inherit;
}

li {

    list-style: none;
}