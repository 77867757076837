@import './Main.css';

/* CARD */

.card {
    
    position: relative;
    width: 30%;
    height: 340px;
    border-radius: 10px;
    cursor: pointer;
    overflow: hidden;
    transition : box-shadow 0.5s;
}

.card::after {

    content: '';
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
}

.card:hover {

    box-shadow: 0 5px 30px rgb(0 0 0 / 0.5);
}

.card_img {

    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: filter 1s;
}

.card:hover img {

    filter: brightness(1.1);
}

.card_h2 {

    z-index: 20;
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 20px;
    font-weight: 500;
    font-size: 18px;
    color: var(--primary);
}

.card:hover .card_h2 {
    text-shadow: 0 0 10px var(--secondary);
}

@media screen and (max-width: 1230px) {

    .card {

        width: 47%;
    }   
}

@media screen and (max-width: 982px) {

    .card {

        width: 100%;
        height: 255px;
        margin: auto;
    }    
}
